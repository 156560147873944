<template>
  <div class="page">
    <div class="container">
      <div class="section">
        <h3>{{ $t('bankAccountReconciliationDetail.listTitle') }}</h3>
        <div class="list-content">
          <b-table
            style="max-height:800px;"
            :data="bankAccounts"
            :loading="loading"
            :striped="true"
            :sticky-header="true"
            :paginated="true"
            :per-page="10"
            :current-page="1"
            :pagination-simple="false"
          >
            <template slot="empty">
              <empty-list v-if="!loading" />
            </template>

            <b-table-column label="Institution" field="financialName" sortable v-slot="props">{{
              props.row.financialInstitution.name
            }}</b-table-column>
            <b-table-column label="Bank Name" field="name" sortable v-slot="props">{{
              props.row.name
            }}</b-table-column>
            <b-table-column label="Account Number" field="accountNumber" sortable v-slot="props">{{
              props.row.accountNumber
            }}</b-table-column>
            <b-table-column field="glAccountBalance" label="G/L Balance" sortable v-slot="props">{{
              props.row.glAccountBalance | currency
            }}</b-table-column>
            <b-table-column
              field="bankAccountBalance"
              label="Account Balance"
              sortable
              v-slot="props"
              >{{ props.row.bankAccountBalance | currency }}</b-table-column
            >
            <b-table-column
              field="summedReconciliationAmount"
              label="Reconciliation Balance"
              sortable
              v-slot="props"
              >{{ props.row.summedReconciliationAmount | currency }}</b-table-column
            >
            <b-table-column
              field="reconciliationDifferenceAmount"
              label="Difference"
              sortable
              v-slot="props"
              >{{ props.row.reconciliationDifferenceAmount | currency }}</b-table-column
            >
            <b-table-column
              field="bankAccountListUnreconciledCount"
              label="# Account Unrec."
              sortable
              v-slot="props"
              >{{ props.row.bankAccountListUnreconciledCount }}</b-table-column
            >
            <b-table-column
              field="checkEntryListUnreconciledCount"
              label="# Check Entries Unrec."
              sortable
              v-slot="props"
              >{{ props.row.checkEntryListUnreconciledCount }}</b-table-column
            >
            <b-table-column field="accountType" label="Account Type" v-slot="props">{{
              props.row.accountType
            }}</b-table-column>
            <b-table-column field="createdDate" label="Created" v-slot="props">{{
              props.row.createdDate | date
            }}</b-table-column>
            <b-table-column v-slot="props">
              <router-link
                class="button is-info is-light is-small is-outline is-rounded"
                :to="{
                  name: 'bankAccount',
                  params: {
                    id: props.row.bankAccountID
                  }
                }"
                >Details</router-link
              >
            </b-table-column>
            <b-table-column v-slot="props">
              <router-link
                class="button is-info is-light is-small is-outline is-rounded"
                :to="{
                  name: 'bankAccountReconcile',
                  params: {
                    id: props.row.bankAccountID,
                    finInstBankName: `${props.row.name} - ${props.row.financialInstitution.name}`,
                    defaultTargetDate: props.row.defaultTargetDate
                  }
                }"
                >Reconcile</router-link
              >
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EmptyList from '@/components/EmptyList'

export default {
  components: { EmptyList },

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapState({
      bankAccounts: state => state.bankaccount.bankAccounts
    })
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  mounted() {
    this.reload()
  },

  methods: {
    ...mapActions('bankaccount', ['loadBankAccounts']),

    async reload() {
      this.loading = true
      await this.loadBankAccounts(true)
      this.loading = false
    }
  },

  i18n: {
    messages: {
      en: { bankAccountReconciliationDetail: { listTitle: 'Manage Reconciliation Accounts' } }
    }
  }
}
</script>
